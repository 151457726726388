<template>
    <div>
        <vue-header title="添加用户" :fixed="true"/>
        <div class="qr-box">
            <div class="identify">请使用<span>微信</span>扫一扫</div>
            <div class="identify">扫描以下二维码</div>
            <div class="qrcode" v-if="qrcode">
                <img :src="qrcode"/>
            </div>
            <div class="qr-prompt">温馨提示：扫码用户是其他业务员的下线，则该用户不会出现在您的客户列表里</div>
        </div>
    </div>
</template>
<script>
    import { makecode } from "@/utils";
    import vueHeader from '@/components/header';
    export default {
        name: 'qr_code',
        components:{
            vueHeader
        },
        data(){ 
            return {
                qrcode:''
            }
        },
        created:function(){
            let code = `${document.location.protocol}//${window.location.host}/redEnvelope/qr_add/${this.$route.params.id}`;
            this.qrcode = makecode(code);
        }
    }
</script>

<style lang="scss" scoped rel="stylesheet/scss">
  @import 'qr_code';
</style>